

.navbar {
  background: #E8AA42;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19.2px;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  text-shadow: 0 0.0625rem 0.0625rem;
  box-shadow: 0 0.3125rem 0.625rem #000000;
  border-radius: 0.5rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;  
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  /* padding-right: 0rem;
    padding-left: 0rem; */
}

@media only screen and (max-width: 75rem) {
  .container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.navbar-logo {
  color: #1c2237;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 32px;
  display: flex;
  align-items: center;
}

.navbar-logo:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
}

.navbar-icon {
  margin-right: 8px;
  color: #1c2237;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

/* .nav-item {
    height: 5rem;
    border-bottom: 0.125rem solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 0.125rem solid #231955;
  }
   */
.nav-links {
  color: #1c2237;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  margin: auto;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.squareLogo {
  width: 1.5rem;
  height: auto;
}

@media screen and (max-width: 61.9375rem) {
  .NavbarItems {
    position: relative;
  }

  .navbar {
    background: #231955;
  }

  .navbar-logo {
    color: #fff;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 5rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #231955;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  /* .nav-links {
    text-align: center;
    padding: 32px;
    width: 100%;
    display: table;
    color: #fff;
  } */

  /* .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  } */

  /* .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    } */

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;            
    font-size: 1.75rem;
    cursor: pointer;
    line-height: 1;
  }

  .fa-times {
    color: #fff;
    font-size: 32px;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7.5rem;
  }
}


.dropdwonHeader {
  max-height: 80vh;
  overflow: scroll;
}


@media only screen and (max-width: 47.9375rem) {
  .dropdwonHeader {
    max-height: 50vh;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dropdwonHeader {
    max-height: 40vh;
  }
}

@media only screen and (max-width: 30rem) {
  .dropdwonHeader {
    max-height: 30vh;
  }
}

.main-menu .navbar-collapse li a {
  position: relative;
  display: block;
  font-size: 1.125rem;
  opacity: 1;
  font-weight: 700;
  color: var(--color-body);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* .main-menu {
  background-color: red;
} */

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a,
.main-menu .navbar-collapse li>a:hover {
  color: #204ecf;
  color: #0769b4;
}

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a,
.main-menu .navbar-collapse li>a:hover {
  color: #204ecf;
  /* text-decoration: underline; */
}

.main-menu .navbar-collapse li.dropdown .dropdown-btn {
  cursor: pointer;
  font-size: 0.8125rem;
  margin-left: 0.5rem;
}

.main-header.header-two:not(.fixed-header) .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
  color: white;
}

.main-header.header-three .main-menu .navbar-collapse>ul>li.dropdown>.dropdown-btn {
  color: white;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.main-header.header-three .main-menu .navbar-collapse>ul>li:hover>.dropdown-btn,
.main-header.header-three .main-menu .navbar-collapse>ul>li.current>.dropdown-btn {
  color: #FFB113;
}


/* style.css | http://localhost:3000/assets/css/style.css */

@media only screen and (max-width: 75rem) {  

  .logo {    
    width: 14.5rem;
  }
}

.container {
  overflow-x: hidden;
}

/* bootstrap-4.5.3.min.css | http://localhost:3000/assets/css/bootstrap-4.5.3.min.css */

@media (max-width: 61.9988rem) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    /* padding-right: 0; */
    /* padding-left: 0; */
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  /* style.css | http://localhost:3000/assets/css/style.css */

  

}


/* style.css | http://localhost:3000/assets/css/style.css */

@media only screen and (max-width: 35.9375rem) {
  .top-inner .top-left {
    font-size: 0.9375rem;
  }
}

/* Inline #13 | http://localhost:3000/contact */

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a,
.main-menu .navbar-collapse li>a:hover {
  color: var(--color-primary);
  border-radius: 7%;
  height: max-content;
  transition: all 0.5s ease;
}


/* style.css | http://localhost:3000/assets/css/style.css */
