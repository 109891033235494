
.CorporateNew .card-header{
    font-size: 1.25rem;
    font-family: var(--font-body-primary);
    font-weight: 500;
    margin-bottom: 8px;


    @media only screen and (max-width: 48rem) {
        font-size: 1rem;
        
    }
}

.CorporateNew .list-items-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;

    @media only screen and (max-width:1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width:500px) {
        grid-template-columns: repeat(1, 1fr);
    }
}