.testimonial-company-logo-img {
  width: 7.5rem;
  height: 3.125rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.testimonial-section .testimonial-company-logo-img {
  background-position: left;
}

.testimonial-section .testimonial-company-logo-img.cgi {
  width: 4.375rem;
}
.testimonial-section .testimonial-company-logo-img.motive-minds,
.testimonial-section .testimonial-company-logo-img.mindsprint {
  width: 9.375rem;
}
.testimonial-section .testimonial-company-logo-img.mercedes-benz {
  width: 12.5rem;
}

/* Student section */

.student-list-section .testimonial-company-logo-img {
  width: 7.5rem;

  @media only screen and (max-width:48rem){
    width: 5.625rem;
  }
}

.student-list-section .testimonial-company-logo-img.mercedes-benz{
  width: 11.25rem;
  @media only screen and (max-width:48rem){
    width: 9.375rem;
  }
}

.student-list-section .testimonial-company-logo-img.bosch{
  @media only screen and (max-width:48rem){
    width: 6.25rem;
  }
}


.student-list-section .testimonial-company-logo-img.cgi{
  @media only screen and (max-width:48rem){
    width: 3.125rem;
  }
}

.student-list-section .testimonial-company-logo-img.tietoevry{
  width:9.375rem;

  @media only screen and (max-width:48rem){
    width: 7.5rem;
  }
}

.student-list-section .testimonial-company-logo-img.pwc,
.student-list-section .testimonial-company-logo-img.wipro,
.student-list-section .testimonial-company-logo-img.exide,
.student-list-section .testimonial-company-logo-img[data-company="l&t"] {
  height: 4.375rem !important;
  @media only screen and (max-width:48rem){
    height: 3.4375rem !important;
  }
}
