.about-man {
    z-index: 1;
    text-align: center;
    position: relative;

    >img {
        height: 600px;
    }
}

.about-man:before {
    content: '';
    left: 60%;
    top: 10%;
    z-index: -1;
    position: absolute;
    width: 480px;
    height: 480px;
    background: #f7f7f7;
    line-height: 540px;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translate(-55%, -10%);
    -ms-transform: translate(-55%, -10%);
    transform: translate(-55%, -10%);
}


#moduleButton {
    margin: auto;
    background-color: #29aae1;
    padding: 0.3rem 0.9rem;
    font-weight: 600;
    color: white;
    margin-bottom: 1rem;
}

#moduleButton1 {
    margin: auto;
    margin-bottom: 1rem;

    >h5 {
        color: #29aae1;
        margin: 0%;
    }
}

#moduleButton1:hover {
    background-color: #29aae1;
    transition: 1s;

    >h5 {
        color: white;
        transition: 1s;
    }
}

.heading {
    text-align: center;    
}

.subHeading {
    text-align: center;
    margin-top: 6rem;    
}


@media only screen and (max-width: 480px) {
    .about-man {
        margin: auto;

        >img {
            height: 400px;
        }
    }

    .about-man:before {
        left: 53%;
        top: 16%;
        width: 300px;
        height: 300px;
    }

    .heading {
        font-size: 2rem;
    }

    .subHeading {
        font-size: xx-large;
    }


}