.Othercourses .otherCardHeading {
  font-size: 1.75rem;
  font-family: var(--font-bold-primary);
  @media only screen and (max-width: 42.1875rem) {
    font-size: 1.25rem;
  }
}

.Othercourses-descrption {
  font-size: 1.125rem !important;
  font-family: var(--font-body-primary);

  @media only screen and (max-width: 42.1875rem) {
    font-size: 1rem !important;
  }
}

.Othercourses-card {
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.Othercourses-card:nth-child(4) .otherCardHeading{

    @media only screen and (min-width:1025px){
        height: 8rem;
    }
    
}

.Othercourses-cards-container {
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
  row-gap: 2.5rem;

  @media only screen and (max-width: 64rem) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  @media only screen and (max-width: 42.1875rem) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }
}

.Othercourses-image-wrapper {
    height: 12.5rem;
}

.Othercourses-image {
  height: 12.5rem;
  object-fit: contain !important;
}
