.student-list-section {
  margin-top: 40px;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.student-list-section-heading {
  margin-bottom: 32px;
}

.student-list-section ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.student-list-section .student-list{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.student-list-section .company-img-container {
  display:flex;
  justify-content: center;
}
.student-list-section .company-img-container img {
  max-width: 6rem;
  height: auto;
  object-fit: contain;
}

.student-list-section .student-name {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-black);
  @media only screen and (max-width:768px){
    font-size: 20px;
  }
}

.student-list-section .slick-slide {

  white-space: nowrap;
}

.student-list-section .slick-list {
  overflow: hidden; 
  
}
.student-list-section .student-list-content{
  display: flex;
  flex-direction: column;
  gap:16px;
  align-items: center;
}
.student-list-section .slick-slide img {
  display: block;  
  max-height: auto;
  object-fit: contain;
  min-width:200px;
}

.student-list-section .slide-item {  
  text-align: center;
}

.student-list-section .ri-section-title {
  margin-bottom: 1.5rem; 
}