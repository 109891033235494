body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.barlow-condensed-thin {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-condensed-extralight {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-condensed-light {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-condensed-regular {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-condensed-medium {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-condensed-semibold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-condensed-bold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-condensed-extrabold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-condensed-black {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-condensed-thin-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-condensed-extralight-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-condensed-light-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-condensed-regular-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-condensed-medium-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-condensed-semibold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-condensed-bold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-condensed-extrabold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-condensed-black-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 900;
  font-style: italic;
}