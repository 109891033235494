.about-us-content {
  font-size: 1.25rem;
  color: var(--color-black);
  font-family: var(--font-body-primary);
}

.about-us-heading {
  font-weight: 700;
  color: var(--color-secondary);
  font-family: var(--font-bold-primary);
}

.about-us-author {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 700;
  font-size: 1.25rem;

  @media only screen and (max-width: 48rem) {
    font-size: 0.875rem;
  }
}

.about-us-card-item p {
  font-size: 0.875rem;
}
