.sap-module-container,
.ri-page-container {
  max-width: 1400px;
  margin: auto;
}
.sap-module,
.ri-page {
  padding: 3rem 1.5rem;

  @media only screen and (max-width: 768px) {
    padding-top: 1.5rem;
  }
}

.sap-module-header,
.ri-page-header {
  margin-bottom: 3rem;
}

.sap-module-section {
  margin-top: 3rem;
}

.sap-module-header,
.ri-page-header {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 4rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.sap-module-header-img,
.ri-page-image {
  max-width: 500px;
  height: auto;
  width: 100%;
  max-height: 350px;
  object-fit: contain;

  @media only screen and (max-width: 1024px) {
    max-width: 400px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.sap-module-faq-accordion {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 48rem) {
    grid-template-columns: 1fr !important;
  }

  @media only screen and (max-width: 64rem) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sap-module .sap-module-what-learn .card,
.sap-module-faq-accordion .card {
  border: none;
  background-color: unset;
  border-radius: none;
}

.sap-module-faq-accordion .card-header,
.sap-module .sap-module-what-learn .card-header,
.sap-module .sap-module-what-learn .subcard-header,
.sap-module .faq-accordion .card-header {
  font-size: 1.25rem;
  font-family: var(--font-body-primary);
  font-weight: 500;

  @media only screen and (max-width: 48rem) {
    font-size: 1rem;
  }
}

.sap-module .sap-module-dot-style {
  width: 1.25rem;
  height: auto;
  margin-right: 0.75rem;
}

.sap-module .sap-module-what-learn .subcard-header {
  padding-left: 2rem;
}

.sap-module-description, .ri-page-description {
  font-size: 1.25rem !important;
  color: var(--color-black);
  font-family: var(--font-body-primary) !important;
  text-align: justify;

  @media only screen and (max-width: 48rem) {
    font-size: 1rem;
  }
}

.sap-module-heading, .ri-page-heading, .ri-page-subheading {
  margin-bottom: 1.5rem;
  text-align: left;
  font-size: 2rem !important;
  font-family: var(--font-bold-primary);

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem !important;
  }
}

.ri-page-subheading {
  font-size: 1.5rem !important;
}

.sap-module-what-learn-item-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media only screen and (max-width: 48rem) {
    grid-template-columns: repeat(1, 1fr);
  }
}

[data-hasSecondColumn="false"] {
  grid-template-columns: repeat(1, 1fr);
}

[data-hasSecondColumn="false"] .what-you-learn-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;

  @media only screen and (max-width: 48rem) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sap-module-what-learn-item-content .card {
  margin-bottom: 1rem;
}
