.testimonial-section.container {
  max-width: 87.5rem;
}
.testimonial-section .paper {
  --paper-color: #ecf1f5;
  --paper-dark: #e9eff6;
}

.testimonial-section {
  --paper-shadow: #54595d;

  margin-top: 32px;
  margin-bottom: 32px;
  padding: 16px;
}

.testimonial {
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
}

.testimonial:hover {
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.6);
}

.testimonial:hover .ri-quote{
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.6);
}

.testimonial .testimonial-content {
  padding: 3rem 1rem;
  height: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
}

.testimonial .testimonial-content-name {
  font-size: 1.5rem;
  @media only screen and (max-width: 47.9375rem) {
    font-size: 1.25rem;
  }
}

.testimonial-content p {
  font-size: 0.875rem;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .testimonial-content p {
    font-size: 0.75rem;
  }
}

.testimonial .testimonial-content .text {
  font-size: 1rem;
}

.testimonial .testimonial-content .name {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.testimonial-section .testimonials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;

  @media only screen and (max-width: 64rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 47.9375rem) {
    grid-template-columns: 1fr;    
  }
}

.testimonial-section .company-info {
  margin-top: 8px;
  font-weight: 500;
}

.testimonial-section .company-info img {
  max-width: 8rem;
  height: auto;  

  /* @media only screen and (max-width: 767px) {
    max-width: 80px;
    height: 32px;
  } */
}

.testimonial-section .load-more{
  text-align: center;
  margin: 2.5rem 0 1.5rem;
  
}
.testimonial-section .load-more button {
  min-width: 9.375rem;
}
.testimonial-section .testimonial {
  position: relative;
  background-image: linear-gradient(
    to right bottom,
    #c9e8f5,
    #d2eaf6,
    #daebf6,
    #e1edf6,
    #e8eff5
  );
}
